import React from "react"
import Layout from "../../components/layoutElMirage"
import MeetthePastorElMirage from "../../components/meetthePastorElMirage"
import MeettheTeamElMirage from "../../components/meettheTeamElMirage"
import SEO from "../../components/seo"
import PopupLive from "../../components/popupLiveElMirage"
import SermonSeriesElMirage from "../../components/sermonSeriesElMirage"
import PopupPodcast from "../../components/popupPodcast"
import PageHeaders from "../../components/pageHeaders"

const SermonPost = () => (
  <Layout>
    <SEO title="Home" />
    <SermonSeriesElMirage />
    <MeetthePastorElMirage />
  </Layout>
)

export default SermonPost
